.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .login-page h2 {
    margin-bottom: 20px;
  }
  
  .login-page form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .login-page .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .login-page .form-group label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .login-page .form-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .login-page button {
    padding: 10px 20px;
    background-color: #E2384D;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .login-page button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .login-page .error {
    color: #ff0000;
    margin-bottom: 10px;
  }
  
  .login-page .signup-link {
    margin-top: 20px;
  }
  
  .login-page .signup-link a {
    color: #E2384D;
    text-decoration: none;
  }