/* Overall Page Styles */


.users {
    display: flex; flex-direction: column; height: 100vh; }
     
     .main-content {
       background-color: #f9f9f9;
       padding: 20px;
       border-radius: 8px;
       box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
     }
     
     .userlistheader {
       display: flex;
       align-items: center;
       justify-content: space-between;
       margin-bottom: 20px;
       margin-top: -150px;
     }
  
  /* Table Styles */
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
  }
  
  th, td {
    padding: 12px 15px;
    text-align: left;
  }
  
  th {
    background-color: #E2384D;
    color: #fff;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  tr:hover {
    background-color: #e0e0e0;
  }
  
  /* Pagination Styles */
  .pagination {
    list-style: none;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination li {
    margin: 0 5px;
  }
  
  .pagination li button {
    border: none;
    background-color: #3f51b5;
    color: #fff;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .pagination li.active button {
    background-color: #1a237e;
  }
  
  /* Search Input Styles */
  input[type="text"] {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
  }