.dashboard { display: flex; flex-direction: column; height: 100vh; }
.top-bar {
display: flex;
justify-content: space-between;
align-items: center;
background-color: #000;
padding: 10px 20px;
color: #fff;
}

.logo {
    height: 50px;
  }
.sitename {
  font-size: 32px;
font-weight: bold;
margin-right: 1150px;
}

.user-info {
display: flex;
align-items: center;
}

.profile-picture {
width: 40px;
height: 40px;
border-radius: 50%;
margin-right: 10px;
}

.fullname {
font-weight: bold;
}

.content-wrapper {
display: flex;
flex: 1;
}

.left-menu {
width: 200px;
background-color: #f2f2f2;
padding: 20px;
}

.user-type {
font-size: 18px;
font-weight: bold;
margin-bottom: 20px;
}

.left-menu ul {
list-style-type: none;
padding: 0;
}

.left-menu li {
margin-bottom: 10px;
cursor: pointer;
}

.left-menu li.selected {
font-weight: bold;
color:#E2384D
}

.main-content {
flex: 1;
padding: 20px;
}

.content {
text-align: center;
}

.membership-tier {
color: red;
}

.membership-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .membership-table th,
  .membership-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ccc;
  }
  
  .membership-table th {
    background-color: #f2f2f2;
  }
  
  .upgrade-button,
  .cancel-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .cancel-button {
    background-color: #dc3545;
  }
  
  @media screen and (max-width: 600px) {
    .membership-table {
      font-size: 14px;
    }
  
    .membership-table th,
    .membership-table td {
      padding: 8px;
      text-align: left;
    }
  
    .membership-table th {
      font-size: 12px;
    }
  
    .upgrade-button,
    .cancel-button {
      font-size: 12px;
      padding: 4px 8px;
    }
  }

.app-download {
margin-top: 40px;
}

.app-download p {
margin-bottom: 10px;
}

.app-store-logos {
display: flex;
justify-content: center;
}

.playstorelogo {
width: 130px;
height: 40px;
margin: 0 10px;
}
.appstorelogo {
    width: 120px;
    height: 40px;
    margin: 0 10px;
    }
    

@media screen and (max-width: 600px) {
.content-wrapper {
flex-direction: column;
}

.left-menu {
width: 100%;
padding: 10px;
}

.main-content {
padding: 10px;
}
}