.help-page {
    background-color: #f8f8f8;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    margin-top: -140px;
    flex: 1;
}

.title {
    font-size: 2rem;
    margin-bottom: 30px;
    margin-top: 0;
}

.faq-container {
    margin-bottom: 40px;
}

.faq-item {
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.question-container {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: #ffffff;
    transition: background-color 0.3s ease;
}

.question-container:hover {
    background-color: #f5f5f5;
}

.question {
    margin: 0;
    font-size: 1.1rem;
    color: #333;
    flex: 1;
}

.chevron {
    font-size: 1.5rem;
    color: #E2384D;
    transition: transform 0.3s ease;
}

.chevron.up {
    transform: rotate(180deg);
}

.answer-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.answer-container.show {
    max-height: 500px;
}

.answer {
    padding: 16px;
    margin: 0;
    color: #666;
    line-height: 1.5;
}

.top-bar {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
    height: 50px;
}

.sign-in-btn {
    background-color: #E2384D;
    color: #ffffff;
    border: none;
    padding: 8px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sign-in-btn:hover {
    background-color: #d1293d;
}

.footer {
    background-color: #E2384D;
    color: #ffffff;
    padding: 20px 0;
    text-align: center;
    margin-top: auto;
}

.footer ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer ul li {
    display: inline;
    margin-right: 20px;
}

.footer ul li:last-child {
    margin-right: 0;
}

.footer a {
    color: #ffffff;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .container {
        padding: 15px;
        margin-top: -100px;
    }

    .title {
        font-size: 1.75rem;
    }

    .footer ul li {
        display: block;
        margin: 10px 0;
    }
}