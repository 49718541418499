.welcome-page {
  background-image: url('../assets/Images/welcomebackground.png');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.welcome-content {
  flex: 1;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding-bottom: 400px;

}
  
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .logo {
    height: 50px;
  }
  
  .sign-in-btn {
    background-color: #E2384D;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  main {
    text-align: center;
  }
  
  h1 {
    font-size: 48px;
    margin-bottom: 20px;
    padding-top: 150px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 30px;
  }
  
  .username-input {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    
  }
  
  .textbox {
    width: 300px;
    padding: 10px;
    font-size: 16px;
  }
  
  .get-started-btn {
    background-color: #E2384D;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  footer {
    background-color: #E2384D;
    padding: 20px;
    text-align: center;
  }
  
  footer ul {
    list-style: none;
    padding: 0;
  }
  
  footer li {
    display: inline-block;
    margin: 0 10px;
  }
  
  footer a {
    color: #fff;
    text-decoration: none;
  }

  .features-section {
    background-color: #000;
    padding: 40px;
    margin-top: -140px;
  }
  
  .feature {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .feature-text {
    flex: 1;
    padding-right: 20px;
    color:white;
  }
  
  .feature-image {
    flex: 1;
  }
  
  .feature-image img {
    width: 100%;
    max-width: 250px;
    border-radius: 8px;
  }
  
  .feature h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .feature p {
    font-size: 16px;
    line-height: 1.5;
  }

  .app-download {
    margin-top: 40px;
    }
    
    .app-download p {
    margin-bottom: 10px;
    }
    
    .app-store-logos {
    display: flex;
    justify-content: center;
    }
    
    .playstorelogo {
    width: 130px;
    height: 40px;
    margin: 0 10px;
    }
    .appstorelogo {
        width: 120px;
        height: 125px;
        margin: 0 10px;
        }
     