/* ContactUs.css */
.contact-page {
  background-color: #f8f8f8;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  font-size: 2rem;
  margin-bottom: 20px;
  margin-top: -140px; /* Adjusted margin-top to bring the title up */
}

.content {
  margin-bottom: 40px;
  text-align: left;
}

.content p,
.content h2,
.content ul {
  text-align: left; /* Align content to the left */
}

.top-bar {
  background-color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.logo {
  height: 50px;
}

.sign-in-btn {
  background-color: #E2384D;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.footer {
  background-color: #E2384D;
  color: #ffffff;
  padding: 20px 0;
  text-align: center;
}

.footer ul {
  list-style-type: none;
  padding: 0;
}

.footer ul li {
  display: inline;
  margin-right: 20px;
}

.footer ul li:last-child {
  margin-right: 0;
}

.footer a {
  color: #ffffff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-btn {
  background-color: #E2384D;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.success-message {
  color: green;
  margin-top: 10px;
}
