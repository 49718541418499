/* UserDetails.css */

.user-details {
 display: flex; flex-direction: column; height: 100vh; }
  
  .main-content {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
   
  }
  
  .back-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
    font-size: 16px;
  }
  
  .back-button:hover {
    text-decoration: underline;
  }
  
  h2 {
    font-size: 24px;
    color: #333;
  }
  
  .tabs {
    display: flex;
    border-bottom: 2px solid #ccc;
  }
  
  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    color: #666;
    transition: all 0.3s ease;
  }
  
  button:hover {
    color: #333;
  }
  
  button.active {
    color: #333;
    border-bottom: 2px solid #333;
  }
  
  .tab-content {
    margin-top: 20px;
  }
  
  .tab-content p {
    margin-bottom: 10px;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 5px;
  }
  
  img {
    max-width: 200px;
    border-radius: 8px;
    margin-top: 10px;
  }
  