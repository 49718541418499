.dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
}


.dashboard-content {
  flex: 1;
  padding: 20px;
}

.analytics-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.analytics-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  width: 300px;
  text-align: center;
}

.analytics-card h3 {
  margin-top: 0;
}

.analytics-card p {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.user-counts {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.user-counts div {
  text-align: center;
}

.user-counts span {
  display: block;
}