
.refund-page {
    font-family: Arial, sans-serif;
  }
  
  .top-bar {
    background-color: #f0f0f0;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    height: 50px;
  }
  
  .sign-in-btn {
    padding: 8px 20px;
    font-size: 16px;
    border: none;
    background-color: #E2384D;
    color: #fff;
    cursor: pointer;
  }
  
  .container {
    margin: 20px auto;
    max-width: 800px;
    padding: 0 20px;
    margin-top: -140px;
  }
  
  .title {
    margin-bottom: 20px;
  }
  
  .content {
    line-height: 1.6;
    
  }
  
  .footer {
    background-color: #E2384D;
    padding: 20px;
    text-align: center;
  }
  
  .footer ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer ul li {
    display: inline-block;
    margin-right: 10px;
  }
  
  .footer ul li a {
    color: #333;
    text-decoration: none;
  }
  