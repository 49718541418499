.signup-page {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .signup-page h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .stepper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .step {
    flex: 1;
    text-align: center;
    padding: 10px;
    background-color: #ccc;
    color: #333;
  }
  
  .step.active {
    background-color: #E2384D;
    color: #fff;
  }
  
  .form-step {
    margin-bottom: 20px;
  }
  
  .form-step label {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  .form-step input,
  .form-step select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-actions {
    text-align: center;
    margin-top: 20px;
  }
  
  .form-actions button {
    background-color: #E2384D;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 5px;
  }
  
  .form-actions button:hover {
    background-color: #E2384D;
  }