.account-details {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .account-details h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .account-details img {
    display: block;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 auto 20px;
  }
  
  .account-details form {
    display: flex;
    flex-direction: column;
  }
  
  .account-details input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .account-details button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .account-details button:hover {
    background-color: #0056b3;
  }
  
  .account-details button[type="button"] {
    margin-top: 10px;
  }
  
  .account-details .change-password {
    margin-top: 20px;
    text-align: center;
  }
  
  .account-details .change-password button {
    background-color: #dc3545;
  }
  
  .account-details .change-password button:hover {
    background-color: #c82333;
  }