/* PaymentInfo.css */
.payment-info {
  font-family: Arial, sans-serif;
 margin-top: -150px;
  padding: 20px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.payment-tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.payment-tab {
  flex: 1;
  padding: 10px;
  background-color: #f5f5f5;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.3s ease;
}

.payment-tab:hover {
  border-color: #007bff;
}

.payment-tab.active {
  border-color: #007bff;
  background-color: #fff;
}

.payment-tab img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.upi-logo {
  width: 32px !important;

  margin-right: 8px;
}


.payment-tab span {
  font-weight: bold;
}

.payment-method {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.payment-method h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.payment-method p {
  margin-bottom: 10px;
}

.payment-method input[type='text'],
.payment-method input[type='number'] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.payment-method button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 20px;
  }

  .payment-tabs {
    flex-wrap: wrap;
  }

  .payment-tab {
    flex-basis: 50%;
    padding: 8px;
  }

  .payment-method {
    padding: 10px;
  }

  .payment-method h2 {
    font-size: 16px;
  }

  .payment-method input[type='text'],
  .payment-method input[type='number'] {
    padding: 8px;
  }

  .payment-method button {
    padding: 8px 16px;
  }

  .card-input {
    position: relative;
  }

  .card-input input[type='text'] {
    padding-right: 40px;
  }

  .card-input svg {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}
.payment-history-table {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.table-header {
  display: none;
}

.table-row {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.table-cell {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.label {
  font-weight: bold;
  margin-right: 0.5rem;
}

.value {
  text-align: right;
}

.payment-id {
  font-weight: bold;
}

.payment-method {
  text-transform: capitalize;
}

.membership-type {
  text-transform: capitalize;
}

.date-range {
  font-size: 0.9rem;
}

.payment-amount {
  font-weight: bold;
  color: #4caf50;
}

.payment-status {
  text-transform: capitalize;
  font-weight: bold;
}

@media (min-width: 768px) {
  .table-header {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    background-color: #f5f5f5;
    font-weight: bold;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
  }

  .table-row {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
    background-color: transparent;
    box-shadow: none;
    border-bottom: 1px solid #e0e0e0;
    padding: 1rem;
  }

  .table-cell {
    margin-bottom: 0;
  }

  .label {
    display: none;
  }
}